import React, { useState } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { AbsoluteCenter, Box, Checkbox, Divider, Flex, Input, ModalBody, ModalFooter, Text } from '@chakra-ui/react';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useLazyGetDocumentsQuery } from '~/lib/documents/api';
import { useAppDispatch } from '~/lib/hooks';
import { addNotification } from '~/lib/notification/slice';
import { useLazyAuthQuery } from '~/lib/user/api';

import { YaButton } from './yandex-login-button';

import styles from './email-screen.module.css';

const EMAIL_REGEX = /^(?=.{1,254}$)(?=.{1,64}@)[^\s@]+@[^\s@]+\.[^\s@]+$/i;

interface EmailScreenProps {
    setOpenPinScreen: (value: boolean) => void;
    setEmail: (value: string) => void;
    email: string;
    isTokenError: boolean;
}

export const EmailScreen = ({ setOpenPinScreen, setEmail, email, isTokenError }: EmailScreenProps) => {
    const dispatch = useAppDispatch();
    const [acceptLegal, setAcceptLegal] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');

    const [trigger] = useLazyAuthQuery();

    const [triggerGetDocuments] = useLazyGetDocumentsQuery();

    const handlePhoneInputChange: React.ComponentProps<'input'>['onChange'] = (e) => {
        const value = e.target.value;

        setEmail(value);
    };

    const handleGetPin = async () => {
        if (!EMAIL_REGEX.test(email)) {
            setErrorEmail('Введите корректный email');
            return;
        }

        setOpenPinScreen(true);

        if (isTokenError) {
            return;
        }

        const data = await trigger({ email });

        if (data.data) {
            dispatch(
                addNotification({
                    status: 'success',
                    title: 'Успех',
                    description: `Пин-код для входа отправлен на почту`,
                }),
            );
        }

        // @ts-expect-error типизация тела ошибки
        if (data.error?.data.error === 'Code already send') {
            setErrorEmail('Введите корректный email');
        }
    };

    const handleAcceptCheckbox: React.ComponentProps<'input'>['onChange'] = (e) => {
        if (e.target.checked) {
            setAcceptLegal(true);
        } else {
            setAcceptLegal(false);
        }
    };

    const handleOpenDocument = async () => {
        const results = await triggerGetDocuments();

        if (results.data) {
            window.open(results.data.data.personal_data);
        }
    };

    return (
        <>
            <ModalBody>
                <TypographyCore variant='h3' className={`${styles['typography-center']} mb-8`}>
                    Вход и регистрация
                </TypographyCore>
                <TypographyCore view='regular' size='s' color='secondary' className={`${styles['typography-center']} mb-20`}>
                    Введите электронный адрес, <br />
                    мы вышлем на него код подтверждения.
                </TypographyCore>
                <Box mb='28px'>
                    <Input
                        type='text'
                        id='email'
                        color='rgba(79, 79, 79, 1)'
                        backgroundColor={'rgba(253, 253, 253, 1)'}
                        placeholder='example@yandex.ru'
                        mb='4px'
                        variant='outline'
                        className={styles.input}
                        onChange={handlePhoneInputChange}
                        borderColor={errorEmail ? 'rgba(202, 50, 20, 1)' : 'rgba(236, 236, 236, 1)'}
                        _hover={{
                            borderColor: 'rgba(3, 77, 33, 1)',
                            backgroundColor: 'rgba(253, 253, 253, 1) !important',
                            color: 'rgba(79, 79, 79, 1) !important',
                        }}
                        _active={{
                            borderColor: 'rgba(3, 77, 33, 1)',
                            backgroundColor: 'rgba(253, 253, 253, 1) !important',
                            color: 'rgba(79, 79, 79, 1) !important',
                        }}
                        _focusVisible={{
                            borderColor: 'rgba(3, 77, 33, 1)',
                            backgroundColor: 'rgba(253, 253, 253, 1)  !important',
                            color: 'rgba(79, 79, 79, 1) !important',
                        }}
                    />
                    {errorEmail || isTokenError ? (
                        <Flex gap={1} mt='4px'>
                            <WarningIcon color='rgba(202, 50, 20, 1)' />
                            <Text fontSize='xs' color='rgba(202, 50, 20, 1)'>
                                {errorEmail}
                                {isTokenError ? 'Вы сделали слишком много попыток, попробуйте через 30 минут' : null}
                            </Text>
                        </Flex>
                    ) : null}
                </Box>
                <ButtonCore
                    view='primary'
                    size='large'
                    className={styles.button}
                    disabled={!email || !acceptLegal || isTokenError}
                    onClick={handleGetPin}
                >
                    Получить код
                </ButtonCore>
            </ModalBody>
            <ModalFooter justifyContent={'center'} flexDirection={'column'} pb={0}>
                <>
                    <Box position='relative' mt='28px' mb='28px' w='100%'>
                        <Divider />
                        <AbsoluteCenter>
                            <TypographyCore view='regular' size='s' className={styles['typography-center']} color='heavy'>
                                или
                            </TypographyCore>
                        </AbsoluteCenter>
                    </Box>
                    <Flex gap='12px' mb='12px'>
                        <YaButton acceptLegal={acceptLegal} />
                    </Flex>
                </>
                <Checkbox
                    lineHeight={'1px'}
                    onChange={handleAcceptCheckbox}
                    colorScheme='brand'
                    size='lg'
                    isInvalid={!!email && !acceptLegal}
                    mb={'8px'}
                >
                    <TypographyCore view='regular' variant='span' color='heavy' size='xs'>
                        Соглашаюсь с{' '}
                        <ButtonCore view='link' onClick={handleOpenDocument} className={styles['document-button']}>
                            политикой
                        </ButtonCore>{' '}
                        обработки персональных данных
                    </TypographyCore>
                </Checkbox>
                {!!email && !acceptLegal ? (
                    <TypographyCore view='regular' size='xs' color='heavy'>
                        Для продолжения необходимо согласиться с политикой
                    </TypographyCore>
                ) : null}
            </ModalFooter>
        </>
    );
};
