'use client';

import Lottie from 'react-lottie-player';
import { Flex } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';

import spinner from '../../public/Animation - spinner.json';

export default function Loading() {
    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });

    return (
        <Flex textAlign={'center'} h={'90vh'}>
            {isMobile ? (
                <Lottie animationData={spinner} play={true} loop={true} style={{ width: '40%', height: '40%', margin: 'auto' }} />
            ) : (
                <Lottie animationData={spinner} play={true} loop={true} style={{ width: '10%', height: '10%', margin: 'auto' }} />
            )}
        </Flex>
    );
}
