import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Button, Link, Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import isNull from 'lodash.isnull';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';
import { env } from 'next-runtime-env';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { useAppDispatch, useAppSelector } from '~/lib/hooks';
import { resetUser } from '~/lib/user/slice';
import { deleteCookie } from '~/app/actions';

import { ProfileLogo } from '../../../../../public/profile-logo';

import styles from './profile-menu.module.css';

const DEV_HOST = 'testsympee.ru';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

export const ProfileMenu = ({ isFetching, isCookiePresent }: { isFetching: boolean; isCookiePresent: boolean | null }) => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state?.user?.data);

    const { isOpen, onClose, onOpen } = useDisclosure();

    const handleResetUser = () => {
        dispatch(resetUser());
        router.push('/catalog');
        signOut({ callbackUrl: `https://${serviceHost}/catalog` });
        deleteCookie('token');
        // @ts-expect-error kmpJsBridge находится в window только при открытии мобильного приложения
        if (typeof window !== 'undefined' && window.kmpJsBridge) {
            // @ts-expect-error kmpJsBridge находится в window только при открытии мобильного приложения
            window.kmpJsBridge.callNative('Logout', JSON.stringify({ message: '' }));
        }
    };

    return (
        <Popover offset={[-85, 20]} autoFocus={false} isOpen={isOpen} onClose={onClose} returnFocusOnClose={false}>
            <PopoverTrigger>
                <Button
                    variant='outline'
                    iconSpacing={0}
                    h='44px'
                    onBlur={onClose}
                    onClick={onOpen}
                    borderColor={'rgba(26, 26, 26, 1)'}
                    borderRadius={'8px'}
                    rightIcon={isOpen ? <ChevronUpIcon width={'24px'} height={'24px'} /> : <ChevronDownIcon width={'24px'} height={'24px'} />}
                    className={styles['menu-button']}
                    isLoading={isFetching || isNull(isCookiePresent)}
                    pl={'16px'}
                    pr={'16px'}
                >
                    {user?.name ? (
                        <TypographyCore size='m' view='bold' className={styles['typography-ellipse']}>
                            {user?.name}
                        </TypographyCore>
                    ) : (
                        <Box pr='4px'>
                            <ProfileLogo w='24px' h='24px' />
                        </Box>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className={styles['menu-list']}>
                <PopoverBody p='0'>
                    <Link as={NextLink} href={'/account'} _hover={{ textDecoration: 'none' }}>
                        <ButtonCore view='ghost' size='large' className={styles.button}>
                            <TypographyCore view='medium' size='m'>
                                Личные данные
                            </TypographyCore>
                        </ButtonCore>
                    </Link>
                    <Link as={NextLink} href={'/my-gifts'} _hover={{ textDecoration: 'none' }}>
                        <Box borderBottom={'1px solid rgba(244, 244, 244, 1)'} pb='8px' mb='8px'>
                            <ButtonCore view='ghost' size='large' className={`${styles.button}`}>
                                <TypographyCore view='medium' size='m'>
                                    Мои подарки
                                </TypographyCore>
                            </ButtonCore>
                        </Box>
                    </Link>
                    <ButtonCore view='ghost' size='large' onClick={handleResetUser} className={styles.button}>
                        <TypographyCore view='medium' size='m'>
                            Выйти
                        </TypographyCore>
                    </ButtonCore>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
