'use client';
import React from 'react';
import { Box } from '@chakra-ui/react';

export const SafeAreaLayout = ({ children, backgroundColor = 'white' }: { children: React.ReactNode; backgroundColor?: string }) => {
    return (
        <>
            {/* Top safe area */}
            <Box w='100%' h='var(--safe-top)' bg={backgroundColor} />

            {children}

            {/* Bottom safe area */}
            <Box w='100%' h='var(--safe-bottom)' bg={backgroundColor} />
        </>
    );
};
