import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import { useAppDispatch } from '~/lib/hooks';
import { useLazyGetUserInfoQuery } from '~/lib/user/api';
import { resetUser } from '~/lib/user/slice';
import { updateUser } from '~/lib/user/slice';
import { deleteCookie } from '~/app/actions';

export const useGetUserInformation = () => {
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [triggerUserInfo, { data, error, isFetching }] = useLazyGetUserInfoQuery();

    useEffect(() => {
        if (data?.data) {
            dispatch(updateUser(data?.data));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (error && 'status' in error && error.status === 403) {
            deleteCookie('token');
            dispatch(resetUser());
            router.push('/catalog/');

            // @ts-expect-error kmpJsBridge находится в window только при открытии мобильного приложения
            if (typeof window !== 'undefined' && window.kmpJsBridge) {
                // @ts-expect-error kmpJsBridge находится в window только при открытии мобильного приложения
                window.kmpJsBridge.callNative('Logout', JSON.stringify({ message: `error: ${JSON.stringify(error.data)}` }));
            }
        }
    }, [dispatch, error, router]);

    return {
        triggerUserInfo,
        isFetching,
    };
};
